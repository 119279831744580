.landing-nav-top {
  background-color: #000;
  color: #fff;
  display: flex;
  gap: 5px;
  justify-content: center;
  font-weight: 500;
  padding: 3px;
}

.landing-nav-top > span {
  color: #f00;
  
  animation: blink 2.5s linear infinite;

}

/* navbar ----------------------------------------------------------- */

.landing-nav{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.landing-nav-main {
  display: flex;
  padding: 15px 10px;
  align-items: center;
  gap: 50px;
  max-width: 1300px;
  margin: auto;
  justify-content: space-between;
}

.landing-nav-logo > img {
  width: 250px;
}

.landing-nav-btn {
  display: none;
}

.landing-nav-main a {
  text-decoration: none;
  color: #000;
}
.landing-nav-menu {
  display: flex;
  gap: 40px;
}

@media only screen and (max-width: 1000px) {
  
  
  .landing-nav-top {

    font-size: .8rem;
    flex-wrap: wrap;
    
  }
  
  /* navbar ----------------------------------------------------------- */
  
  
    
  .landing-nav-main {
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px ;
    gap: 10px;
  }
  .landing-nav-menu {
    padding: 0;
    position: fixed;    
    top: 100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #ffecec;
    transition: 0.4s;
  }
  
  .landing-nav-open{
    
    transform: translateY(-100vh);
    z-index: 2;
  }
  
  .landing-nav-logo > img {
    width: 180px;
    display: flex;
  }
  
  .landing-nav-btn {
    display: block;
    font-size: 1.2rem;
  }
  
  .landing-nav-close-btn{
    color: #f00;
    font-size: 2rem;
  }
  
  .landing-nav-menu-btn{
    align-items: center;
    display: flex;
    align-items:  center;
    gap: 15px;
    font-size: .9rem;
  }
  .landing-nav-menu-btn a i{
    font-size: 1rem;
    color: #ff0000;

  }
  
  .landing-nav-menu-btn i{
    font-size: 1.3rem;
    
  }
  
}

@media only screen and (max-width: 390px) {
  .landing-nav-logo > img {
    width: 150px;
  }
  
  .landing-nav-menu-btn{
    gap: 8px;
  }
  .landing-nav-menu-btn i{
    font-size: 1.1rem;
  }
  
}

@media only screen and (max-width: 345px) {
  .landing-nav-logo > img {
    width: 130px;
  }
  
  .landing-nav-menu-btn{
    font-size: .85rem;
  }
  
}