
.faq-component{
    background-color: rgba(114, 114, 114, 0.096);
}
.faq-component-main{
    padding: 50px 0;
    max-width: 1200px;
    margin: auto;
}

.faq-component-heading{
    font-size: 2.2rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
}

.faq-component-heading span{
    color: #f00;
}

.faq-component-content-col{
    /* border: 1px solid #000; */
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.26);
}

.faq-component-question{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.1rem;
    gap: 10px;
}
.faq-component-question i{
    cursor: pointer;
    font-size: 1.4rem;
}
.faq-component-answer{
    /* display: none; */
    margin-top: 10px;
}


.faq-component-question-active{
    color: #f00;
}


@media only screen and (max-width: 1000px) {

    .faq-component-main{
        padding: 30px 20px;
        max-width: 1200px;
        margin: auto;
    }
    
    
.faq-component-heading{
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: 10px;
}

.faq-component-content-col{
    /* border: 1px solid #000; */
    margin-top: 20px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.26);
}
.faq-component-question{
    font-size: 1rem;
}
.faq-component-answer{
    font-size: .9rem;
    margin-top: 5px;
}

}


